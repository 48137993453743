import PropTypes from 'prop-types';
import { Input as ReactStrapInput, Button } from 'reactstrap';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { InputGroup } from 'reactstrap';

const PasswordInput = ({ className = '', ...props }) => {
  const [type, setType] = useState('password');

  const handleToggle = () => {
    setType((prev) => (prev === 'password' ? 'text' : 'password'));
  };

  return (
    <InputGroup className={`password-input ${className}`}>
      <ReactStrapInput {...props} type={type} />
      <Button color="default" type="button" onClick={handleToggle}>
        <FontAwesomeIcon icon={type === 'password' ? faEye : faEyeSlash} />
      </Button>
    </InputGroup>
  );
};

PasswordInput.propTypes = {
  className: PropTypes.string,
};

export default PasswordInput;
